/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @next/next/no-img-element */
'use client'

type FacebookProps = {
  facebookPixelId: string
}

const FacebookImage = ({ facebookPixelId }: FacebookProps) => {
  return (
    <noscript>
      <img
        height="1"
        width="1"
        style={{ display: 'none' }}
        src={`https://www.facebook.com/tr?id=${facebookPixelId}&ev=PageView&noscript=1`}
      />
    </noscript>
  )
}

export { FacebookImage }
